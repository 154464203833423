export const WHITELISTED_DOMAINS = [
  "hub.helixgame.com",
  "helixgame.com",
  "localhost",
];

export const MAIN_PAGE = "https://hub.helixgame.com/account/details";

export const TEMPORARY_WHITELISTED_REDIRECT_URIS_FOR_TESTING = [
  "https://helixgame.com/exchange/authorize",
  "https://hub.helixgame.com/authorize/end",
];

export const TEMPORARY_SCOPES_FOR_TESTING = ["*"];

export const TEMPORARY_SCOPE_DEFINITIONS_FOR_TESTING: {
  [key: string]: string;
} = {
  "*": "Full access to your account",
};

export default Object.freeze({
  WHITELISTED_DOMAINS,
  MAIN_PAGE,
  TEMPORARY_SCOPES_FOR_TESTING,
  TEMPORARY_WHITELISTED_REDIRECT_URIS_FOR_TESTING,
  TEMPORARY_SCOPE_DEFINITIONS_FOR_TESTING,
});

export const ALLOWED_PORTAL_ORIGINS = [
  "http://localhost:3000",
  "https://helixgame.com",
  "https://hub.helixgame.com",
  "https://www.helixgame.com",
  "https://d9009902.hub-474.pages.dev",
];

export const PORTAL_HELLO_TIMEOUT = 1000 * 60 * 5; // 5 minutes to receive hello
