import { useState, useEffect } from "react";
import useUser from "./hooks/useUser";
import { useNavigate } from "react-router-dom";

import styles from "./App.module.css";
import { createPortal } from "react-dom";
import config from "./config";
import { followInternalRedirect } from "./utils";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from "./components/Loader";

import LoginPage from "./pages/Login";

import AuthorizePage from "./pages/Oauth/Authorize";
import PortalPage from "./pages/Portal";

import { ReactComponent as Logo } from "./assets/logo.svg";

function App() {
  const navigate = useNavigate();
  const { loading, loggedIn, login } = useUser();
  const [loaderEnabled, setLoaderEnabled] = useState(true);

  const [judgement, setJudgement] = useState(false);
  useEffect(() => {
    const { location } = window;
    const currentUri = location.pathname + location.search;
    if (location.pathname === "/portal") return setLoaderEnabled(false);
    if (loading || judgement) return;

    const searchParams =
      currentUri !== "/" ? `?redirect=${encodeURIComponent(currentUri)}` : "";

    setJudgement(true);

    if (!loggedIn && location.pathname !== "/login") {
      setLoaderEnabled(false);
      return navigate(`/login${searchParams}`, {
        replace: true,
      });
    }
    if (location.pathname === "/") {
      if (!loggedIn) {
        navigate(`/login`, {
          replace: true,
        });
        setTimeout(() => setLoaderEnabled(false), 10);
        return;
      }

      return window.location.replace(config.MAIN_PAGE);
    }
    if (loggedIn && location.pathname === "/login") {
      const searchParams = new URLSearchParams(location.search);
      if (!searchParams.has("redirect"))
        return window.location.replace(config.MAIN_PAGE);

      const redirect = searchParams.get("redirect") as string;
      const internal = followInternalRedirect(redirect, navigate);
      if (!internal) return; // don't disable loader if redirecting to external

      setTimeout(() => setLoaderEnabled(false), 10);
      return;
    }
    setLoaderEnabled(false);
  }, [loading]);

  useEffect(() => {
    console.log("overlay enabled", loaderEnabled);
  }, [loaderEnabled]);

  return (
    <>
      {createPortal(<Logo className={styles.logo} />, document.body)}

      <LoadingOverlay active={loaderEnabled} />

      <Routes>
        <Route path="/login" element={<LoginPage loading={loaderEnabled} />} />
        <Route
          path="/oauth2/authorize"
          element={<AuthorizePage loading={loaderEnabled} />}
        />
        <Route path="/portal" element={<PortalPage />} />
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </>
  );
}

function LoadingOverlay({ active = false }: { active?: boolean }) {
  return !active ? null : (
    <div className={styles.loaderInner}>
      <Loader scale={1.75} />
    </div>
  );
}

export default App;
