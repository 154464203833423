import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import useUser from "../../../hooks/useUser";
import usePageConfig from "../../../hooks/usePageConfig";

import styles from "./styles.module.css";
import {
  temporaryDefineScopeForTesting,
  temporaryLookupClientIdForTesting,
  temporaryValidateRedirectUriForTesting,
  temporaryValidateScopesForTesting,
} from "../../../utils";

import type { StateExtendable } from "../../../types/common";

import Loader from "../../../components/Loader";

import { ReactComponent as CheckCircleIcon } from "../../../assets/checkCircleIcon.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/errorIcon.svg";
import { approveOauth2Request } from "../../../api/oauth2";

type State = StateExtendable<{
  client: {
    image: string;
    name: string;
    id: string;
  };
  scopes: string[];
  // redirectUri: string;
  state: string;
}>;

export default function AuthorizePage({ loading }: { loading: boolean }) {
  const { bearer, user, loggedIn } = useUser();
  const { setTitle, setHeight } = usePageConfig();
  const location = useLocation();

  const [state, setState] = useState<State>(() =>
    !location.search.includes("scope=") ||
    !location.search.includes("redirect_uri=") ||
    !location.search.includes("client_id=")
      ? {
          loading: false,
          error: true,
          message: "Invalid request",
        }
      : { loading: true }
  );
  useEffect(() => {
    if (loading || !loggedIn) return;
    setTitle("Authorize | HELIX Account");

    const searchParams = new URLSearchParams(location.search);
    const validationResult:
      | string
      | {
          client: any;
          scopes: string[];
          //   redirectUri: string;
        } = (() => {
      if (
        !searchParams.has("client_id") ||
        //!searchParams.has("redirect_uri") ||
        !searchParams.has("scope")
      )
        return "Invalid request";

      const clientId = searchParams.get("client_id")!,
        // redirectUri = searchParams.get("redirect_uri")!,
        scopes = searchParams.get("scope")!.split(/ +/);

      if (clientId === "") return "Invalid client id";

      //if (redirectUri === "") return "Invalid redirect uri";

      if (scopes.length === 0) return "No scopes provided";

      if (!temporaryValidateScopesForTesting(scopes)) return "Invalid scopes";

      // lookup the client id here
      // im gonna fake it for now

      const client = temporaryLookupClientIdForTesting(clientId);
      if (!client) return "Unknown client id";

      // normally we'd validate the redirect uri against the client's whitelist here
      // we're not gonna do that because im lazy

      /*if (!temporaryValidateRedirectUriForTesting(redirectUri))
        return "Redirect uri not whitelisted";*/

      // all the (definitely legitimate and real) checks have passed
      // now we can show the user the authorize page

      return {
        client: {
          ...client,
          id: clientId,
        },
        scopes,
        //redirectUri,
      };
    })();

    if (typeof validationResult === "string") {
      setHeight(372);
      return setState({
        loading: false,
        error: true,
        message: validationResult,
      });
    }

    setHeight(372 + (30 + 16) * (validationResult.scopes.length - 1) + 40);

    setState({
      loading: false,
      error: false,
      client: validationResult.client,
      scopes: validationResult.scopes,
      // redirectUri: validationResult.redirectUri,
      state: searchParams.get("state") ?? "",
    });
  }, [loading]);

  return (
    <div className={`${styles.root}`}>
      {state.loading && (
        <div className={styles.loader}>
          <Loader scale={1.5} />
        </div>
      )}
      {!state.loading && state.error && (
        <>
          <ErrorIcon className={styles.errorIcon} />
          <div className={styles.error}>{state.message}</div>
        </>
      )}
      {!state.loading && !state.error && (
        <>
          <h1 className={styles.title}>Authorize {state.client.name}</h1>
          <div className={styles.images}>
            <picture
              style={{ backgroundImage: `url(${state.client.image})` }}
            />
            <div className={styles.divider}>--&gt;</div>
            <picture style={{ backgroundImage: `url('${user?.icon_url}')` }} />
          </div>
          <div className={styles.scopes}>
            <div className={styles.scopesTitle}>
              this will allow {state.client.name} to
            </div>
            {state.scopes.map((scope) => (
              <div className={styles.scope} key={scope}>
                <CheckCircleIcon />
                {temporaryDefineScopeForTesting(scope)}
              </div>
            ))}
          </div>
          <button
            className={styles.button}
            onClick={async () => {
              setState({ loading: true });
              const result = await approveOauth2Request(
                state.client.id,
                bearer
              );
              if (!result.success)
                return setState({
                  loading: false,
                  error: true,
                  message: result.error,
                });
              /*const url = new URL(state.redirectUri);
              url.searchParams.set("code", "the-temporary-code");
              if (state.state !== "")
                url.searchParams.set("state", state.state);*/
              window.location.replace(result.data.redirect_uri);
            }}
          >
            Authorize
          </button>
          <button
            className={styles.button2}
            onClick={() => {
              setState({
                loading: false,
                error: true,
                message: "You denied the request",
              });
            }}
          >
            Cancel
          </button>
        </>
      )}
    </div>
  );
}
