import { useState, useEffect, useContext, useCallback, useMemo } from "react";

import { createContext } from "react";
import { createPortal } from "react-dom";

type PageConfig = {
  title: string;
  setTitle: (title: string) => void;
  height: number;
  setHeight: (height: number) => void;
  setDynamicHeight: (handler: (height: number) => [number, number]) => void;
};

const defaultPageHeight = 550;

const PageConfigContext = createContext<PageConfig>({
  title: document.title,
  setTitle: () => {},
  height: defaultPageHeight,
  setHeight: () => {},
  setDynamicHeight: () => {},
});

export default function usePageConfig() {
  const context = useContext(PageConfigContext);

  return context;
}

export function PageConfigProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [title, setTitle] = useState(document.title);
  const setTitleMemoized = useCallback((title: string) => {
    setTitle(title);
  }, []);
  useEffect(() => {
    document.title = title;
  }, [title]);

  const [height, setHeight] = useState(defaultPageHeight);
  const [heightTransitionTime, setHeightTransitionTime] = useState(200);
  const setHeightMemoized = useCallback((height: number) => {
    setHeight((currentHeight) => {
      const difference = Math.abs(currentHeight - height);
      console.log(
        `Changing height by ${difference}px (from ${currentHeight}px to ${height}px), transition time: ${
          difference * 1.2 + 200
        }ms`
      );
      setHeightTransitionTime(difference * 1.2 + 200);
      return height;
    });
  }, []);
  const setDynamicHeight = useCallback(
    (handler: (height: number) => [number, number]) => {
      setHeight((height) => {
        const [newHeight, transitionTime] = handler(height);
        setHeightTransitionTime(transitionTime);
        return newHeight;
      });
    },
    []
  );
  useEffect(() => {
    const root = document.getElementById("root");
    if (!root) return;
    root.style.transition = `height ${heightTransitionTime}ms cubic-bezier(0.34, 1.56, 0.64, 1)`;
    root.style.height = `${height}px`;
  }, [height]);

  return (
    <PageConfigContext.Provider
      value={{
        title,
        setTitle: setTitleMemoized,
        height,
        setHeight: setHeightMemoized,
        setDynamicHeight,
      }}
    >
      {children}
    </PageConfigContext.Provider>
  );
}
