import type { ResultExtendable } from "../types/common";

export type Oauth2Result = {
  redirect_uri: string;
};
export async function approveOauth2Request(
  clientId: string,
  token: string
): Promise<ResultExtendable<Oauth2Result>> {
  try {
    const searchParams = new URLSearchParams({
      client_id: clientId,
    });
    const result = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/sso?${searchParams}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((r) => r.json());

    if (result.code !== 0)
      return {
        success: false,
        data: {
          redirect_uri: "",
        },
        error: result.message,
      };

    return {
      success: true,
      data: result.payload,
    };
  } catch (e: unknown) {
    return {
      success: false,
      data: {
        redirect_uri: "",
      },
      error: (e as Error).message,
    };
  }
}
