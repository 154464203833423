import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import security from "./security";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./hooks/useUser";
import { PageConfigProvider } from "./hooks/usePageConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <React.StrictMode></React.StrictMode>
    <BrowserRouter>
      <PageConfigProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </PageConfigProvider>
    </BrowserRouter>
  </>
);

if (false) security();
