/*
eslint-disable @typescript-eslint/naming-convention
*/

export type PortalMessage<Data = null> = {
	op: number;
	d: Data;
};

export enum PortalOp {
	Client_Hello = 0,
	Portal_HelloResponse = 1,
	Portal_HelloResponseError = 2,
	Client_RequestLogout = 3,
	Portal_LogoutAck = 4,
}

export type Portal_HelloResponse = {
	loggedIn: boolean;
	userId: string | '';
	bearer: string | '';
	user: null | {
		balance: number;
		banned: boolean;
		bio: string;
		country: string;
		email: string;
		icon_url: string;
		id: string;
		own_founder_pass: boolean;
		status: 'OFFLINE' | 'ONLINE';
		username: string;
		verified: boolean;
		whitelisted: boolean;
	};
};

export const ALL_PORTAL_OPS = Object.values(PortalOp).filter(
	(v) => typeof v === 'number'
) as number[];
