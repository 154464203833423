import { useState, useEffect } from "react";
import useUser from "../../hooks/useUser";
import usePageConfig from "../../hooks/usePageConfig";
import { useNavigate, useLocation } from "react-router";

import styles from "./styles.module.css";
import { followInternalRedirect, wrapTransition } from "../../utils";
import config from "../../config";

import { ReactComponent as LoginIcon } from "../../assets/loginIcon.svg";

type ErrorMessage = {
  email: string;
  password: string;
};

export default function LoginPage({ loading }: { loading: boolean }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { login } = useUser();
  const { setTitle, setHeight } = usePageConfig();

  useEffect(() => {
    if (loading) return;
    setTitle("Login | HELIX Account");
    setHeight(260 + 20 + 20 + 100);
  }, [loading]);

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<ErrorMessage>({
    email: "",
    password: "",
  });

  return (
    <div className={`${styles.root}`}>
      <LoginIcon className={styles.icon} />
      <h1 className={styles.title}>Account Login</h1>
      {/*<p className={styles.subtitle}>
        Welcome back! Please enter your details.
  </p>*/}
      <LoginForm
        errorMessage={error}
        onSubmit={async (email, password) => {
          if (email === "")
            return wrapTransition(() =>
              setError({ email: "required", password: "" })
            );
          if (password === "")
            return wrapTransition(() =>
              setError({ email: "", password: "required" })
            );

          wrapTransition(() => setError({ email: "", password: "" }));
          setSaving(true);

          const loginResult = await login(email, password);
          if (!loginResult.success) {
            setSaving(false);
            if (
              loginResult.error.includes("email") ||
              loginResult.error.includes("account")
            )
              return wrapTransition(() =>
                setError({ email: loginResult.error, password: "" })
              );

            return wrapTransition(() =>
              setError({ email: "", password: loginResult.error })
            );
          }

          const searchParams = new URLSearchParams(location.search);
          console.log("red", searchParams.get("redirect"));
          if (!searchParams.has("redirect"))
            return window.location.replace(config.MAIN_PAGE);

          const redirect = searchParams.get("redirect") as string;
          console.log("following redirect", redirect);
          followInternalRedirect(redirect, navigate);
        }}
      />
    </div>
  );
}

function LoginForm({
  onSubmit,
  errorMessage,
}: {
  onSubmit: (email: string, password: string) => void;
  errorMessage: ErrorMessage;
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return (
    <form
      className={styles.form}
      method="post"
      action="#"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit(email, password);
        return false;
      }}
    >
      <div className={styles.group}>
        <div className={styles.label}>
          Email
          {errorMessage.email !== "" && <span> - {errorMessage.email}</span>}
        </div>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.input}
          placeholder="conqr@ibbsinc.com"
          autoComplete="email"
          required
        />
      </div>
      <div className={styles.group}>
        <div className={styles.label}>
          Password
          {errorMessage.password !== "" && (
            <span> - {errorMessage.password}</span>
          )}
        </div>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles.input}
          placeholder="password"
          autoComplete="current-password"
          required
        />
      </div>
      <button type="submit" className={styles.submit}>
        Login
      </button>
    </form>
  );
}
