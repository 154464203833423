import { useContext, useEffect, useState, useMemo, useCallback } from "react";

import { createContext } from "react";
import { sleep } from "../utils";

import type { User } from "../types/user";
import type { ResultExtendable } from "../types/common";

type UserContext = {
  loading: boolean;
  loggedIn: boolean;
  userId: string;
  user: User | null;
  login: (email: string, password: string) => Promise<ResultExtendable>;
  logout: (revoke?: boolean) => Promise<void>;
  fetchUser: () => Promise<void>;
  bearer: string;
};

const localStorageName = "token";

const UserContext = createContext<UserContext>({
  loading: true,
  loggedIn: false,
  userId: "",
  user: null,
  login: async () => ({ success: false, error: "Loading", data: null }),
  logout: async () => {},
  fetchUser: async () => {},
  bearer: "",
});

export default function useUser() {
  const context = useContext(UserContext);

  return context;
}

type AuthPayload = User & {
  authorization: string;
};

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState<User | null>(null);
  const [bearer, setBearer] = useState("");

  const login = useCallback<
    (email: string, password: string) => Promise<ResultExtendable>
  >(async (email: string, password: string) => {
    const result = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    if (result.status === 429)
      return { success: false, error: "Too many requests", data: null };

    if (result.status >= 500)
      return { success: false, error: "Server error", data: null };

    const body = await result.json();
    if (body.code !== 0)
      return { success: false, error: body.message, data: null };

    const payload: AuthPayload = body.payload;

    localStorage.setItem(localStorageName, payload.authorization);
    setBearer(payload.authorization);
    setUserId(payload.id);
    setUser(payload);
    setLoading(false);

    return { success: true, data: null };
  }, []);

  const logout = useCallback(
    async (revoke = true, tokenOverride = "") => {
      // revoke here
      localStorage.removeItem(localStorageName);
      setBearer("");
      setUserId("");
      setUser(null);
    },
    [bearer]
  );

  const fetchUser = useCallback(async (tokenOverride = "") => {
    const result = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/`, {
      headers: {
        Authorization: `Bearer ${tokenOverride || bearer}`,
      },
    });

    if (result.status === 429 || result.status >= 500) {
      setTimeout(() => fetchUser(tokenOverride), 1000);
      return;
    }

    if (result.status === 401 || result.status === 403) {
      logout(false, tokenOverride);
      return;
    }

    const data = await result.json();
    if (data.code !== 0) {
      return console.error(data.message);
    }

    const payload: User = data.payload;
    setUserId(payload.id);
    setUser(payload);
    setLoading(false);
  }, []);

  useEffect(() => {
    let token = localStorage.getItem(localStorageName);
    if (typeof token === "string" && token === "") {
      localStorage.removeItem(localStorageName);
      token = null;
    }
    if (token === null) {
      setLoading(false);
      return;
    }
    setBearer(token);
    fetchUser(token);
  }, []);

  return (
    <UserContext.Provider
      value={{
        loading,
        loggedIn: userId !== "",
        userId,
        user,
        login,
        logout,
        fetchUser,
        bearer,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
