import config from "../config";

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const validateRedirectUri = (uri: string): uri is string => {
  try {
    // if (uri === "/" || uri.startsWith("/?")) return true;
    // if (uri.startsWith("/login")) return false; // prevent redirect loop
    if (!uri.startsWith("https://") && !uri.startsWith("/")) return false;
    console.log("url is not of bad protocol or bad patch");
    const url = new URL(
      uri.startsWith("/")
        ? `${window.location.protocol}//${window.location.hostname}${uri}`
        : uri
    );
    console.log("url is not error");
    return (
      url.hostname === window.location.hostname ||
      config.WHITELISTED_DOMAINS.includes(url.hostname)
    );
  } catch (e) {
    console.log("url is error");
    return false;
  }
};

export const followInternalRedirect = (
  uri: string,
  navigate: (url: string, { replace }: { replace: boolean }) => void
): boolean => {
  if (
    uri === "" ||
    uri === "/" ||
    uri.startsWith("/?") ||
    uri.startsWith("/login")
  ) {
    console.log("found dead end", uri);
    window.location.replace(config.MAIN_PAGE);
    return false;
  }

  if (!validateRedirectUri(uri)) {
    console.log("failed to validate", uri);
    window.location.replace(config.MAIN_PAGE);
    return false;
  }

  if (uri.startsWith("https://")) {
    try {
      const url = new URL(uri);
      if (url.hostname !== window.location.hostname) {
        console.log("detected external redirect", uri);
        window.location.replace(uri);
        return false;
      }
      const newUri = `${url.pathname}${url.search}`;
      console.log("deleted full uri internal redirect", newUri);
      wrapTransition(() =>
        navigate(newUri, {
          replace: true,
        })
      );
      return true;
    } catch (e) {}
  }

  console.log("following internal redirect", uri);
  wrapTransition(() =>
    navigate(uri, {
      replace: true,
    })
  );
  return true;
};

export const wrapTransition = (callback: () => void) => {
  if (!document.startViewTransition) return callback();
  document.startViewTransition(callback);
};

export const temporaryValidateRedirectUriForTesting = (
  uri: string
): boolean => {
  if (uri === "" || !uri.startsWith("https://")) return false;
  try {
    const url = new URL(uri);
    if (url.protocol !== "https:") return false;

    const fullUri = `https://${url.hostname}${url.pathname}`;
    if (
      !config.TEMPORARY_WHITELISTED_REDIRECT_URIS_FOR_TESTING.includes(fullUri)
    )
      return false;

    return true;
  } catch (e) {
    return false;
  }
};

export const temporaryValidateScopesForTesting = (
  scopes: string[]
): boolean => {
  return scopes.every((scope) =>
    config.TEMPORARY_SCOPES_FOR_TESTING.includes(scope.toLowerCase())
  );
};

export const temporaryLookupClientIdForTesting = (
  clientId: string
): {
  image: string;
  name: string;
} | null => {
  const map: {
    [key: string]: {
      image: string;
      name: string;
    };
  } = {
    "2109623166": {
      image: "/bolt.png",
      name: "Build Mode",
    },
  };

  if (typeof map[clientId] === "undefined") return null;
  return map[clientId];
};

export const temporaryDefineScopeForTesting = (scope: string): string => {
  return (
    config.TEMPORARY_SCOPE_DEFINITIONS_FOR_TESTING[scope] ?? "Unknown scope"
  );
};
