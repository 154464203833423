import { useState, useRef, useEffect } from "react";
import useUser from "../../hooks/useUser";

import type { Portal_HelloResponse, PortalMessage } from "./static/types";

import { ALL_PORTAL_OPS, PortalOp } from "./static/types";
import { ALLOWED_PORTAL_ORIGINS, PORTAL_HELLO_TIMEOUT } from "../../config";

export default function PortalPage() {
  const [parent, setParent] = useState<Window | null>(null);
  useEffect(() => {
    if (!window.parent) return;
    setParent(window.parent);
  }, []);

  return (
    <>
      <h1>Portal</h1>
      {parent && <PortalReceiver parent={parent} />}
    </>
  );
}

type PortalState =
  | {
      waitingForHello: true;
    }
  | {
      waitingForHello: false;
      origin: string;
      sentHello: boolean;
    };

function PortalReceiver({ parent }: { parent: Window }) {
  const { loggedIn, loading, user, userId, bearer, logout } = useUser();

  const [state, setState] = useState<PortalState>({ waitingForHello: true });
  const stateRef = useRef<PortalState>({ waitingForHello: true });
  useEffect(() => {
    console.log("[PORTAL] mounted, waiting for hello");
    const onMessage = (e: MessageEvent) => {
      if (
        typeof e.data !== "object" ||
        e.data === null ||
        Array.isArray(e.data)
      )
        return console.log("[PORTAL] invalid message received, ignoring");

      const message = e.data as PortalMessage;

      if (
        // @ts-ignore
        typeof message.type === "string" && // @ts-ignore
        message.type === "webpackWarnings"
      )
        return console.log("[PORTAL] webpack warnings received, ignoring");

      if (typeof message.op !== "number" || message.op < 0)
        return console.log("[PORTAL] invalid opcode received, ignoring");
      if (!ALL_PORTAL_OPS.includes(message.op))
        return console.log("[PORTAL] unknown opcode received, ignoring");

      if (typeof message.d === "undefined")
        return console.log("[PORTAL] invalid data received, ignoring");

      const { op } = message;

      if (stateRef.current.waitingForHello) {
        if (op !== PortalOp.Client_Hello) {
          console.log("[PORTAL] expected hello, got something else, blocking");
          throw new Error("Expected hello, got something else");
        }
        if (typeof e.origin !== "string") {
          console.log("[PORTAL] invalid origin, blocking");
          throw new Error("Invalid origin");
        }
        if (!ALLOWED_PORTAL_ORIGINS.includes(e.origin)) {
          console.log("[PORTAL] untrusted origin, blocking");
          throw new Error("Invalid origin");
        }
        console.log("[PORTAL] hello received, starting communication");
        setState({
          waitingForHello: false,
          origin: e.origin,
          sentHello: false,
        });
        stateRef.current = {
          waitingForHello: false,
          origin: e.origin,
          sentHello: false,
        };
        return;
      }

      if (op === PortalOp.Client_RequestLogout) {
        console.log("[PORTAL] received logout request, logging out");
        return logout();
      }

      console.log(`[PORTAL] received message with opcode ${op}`, message);
    };

    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);

  useEffect(() => {
    if (!state.waitingForHello) return;
    const i = setTimeout(() => {
      console.log("[PORTAL] hello timeout, blocking");
      throw new Error("Hello timeout");
    }, PORTAL_HELLO_TIMEOUT);

    return () => clearTimeout(i);
  }, [state]);

  useEffect(() => {
    if (loading || state.waitingForHello || state.sentHello) return;

    const message: PortalMessage<Portal_HelloResponse> = {
      op: PortalOp.Portal_HelloResponse,
      d: {
        loggedIn,
        user,
        userId,
        bearer,
      },
    };

    parent.postMessage(message, state.origin);
    console.log("[PORTAL] sent hello response");
    setState({
      waitingForHello: false,
      origin: state.origin,
      sentHello: true,
    });
  }, [loading, state]);

  return <pre>{JSON.stringify(state, null, 2)}</pre>;
}
